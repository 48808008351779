import React from 'react';
import events from '../../utils/Events';
import Events from '../../utils/Events';
import {t} from '../../utils/Translator';
import constants from '../../config/constants';
import {App, customReadOnlyUser} from '../../App';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {AppService, CommandsService, DevicesService, MaintenancesService, PositionsService} from '../../services';
import {Scrollbars} from 'react-custom-scrollbars';
import {store} from '../../store';
import app from '../../store/app';
import CommandModal from './command';
import devices from '../../store/devices';
import positions from '../../store/positions';
import {Command, Maintenance, Position} from '../../models';
import {Button, Link, ThemeProvider} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import RouterIcon from "@mui/icons-material/Router";
import MaintenanceIcon from "@mui/icons-material/Workspaces";
import AlignIcon from "@mui/icons-material/FormatAlignJustify";
import CameraIcon from "@mui/icons-material/CameraAlt";
import theme from "../../Theme";
import MapIcon from "@mui/icons-material/LocationOn";
import * as moment from "moment";

export default class DeviceStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            currentTab: 'sensors',
            menuOpen: true
        };
        this.toggle = this.toggle.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.hideCommand = this.hideCommand.bind(this);

        /*if (App.App.user.administrator ||
            !App.App.user.deviceReadonly && !App.App.getPreference('readonly', false)) {

            this.lookupReference('computedAttributesButton').setDisabled(
                Traccar.app.getBooleanAttributePreference('ui.disableComputedAttributes'));
        }*/
        const hideAttributesPreference = App.App.getAttributePreference('ui.hidePositionAttributes');
        this.hideAttributes = {};
        if (hideAttributesPreference) {
            const attributesList = hideAttributesPreference.split(/[ ,]+/).filter(Boolean);
            attributesList.map(v => {
                this.hideAttributes[v] = true;
            })
        }
        this.getAddress = this.getAddress.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

    }

    componentWillReceiveProps(newProps, newState) {
        if (newProps.open !== undefined) {
            this.setState({open: newProps.open})
        }

        if (newProps.device) {
            MaintenancesService.list({deviceId: newProps.device.id}, false).then(m => {
                if (m.length > 0) {
                    this.deviceMaintenance = m[0].deserialize()
                } else this.deviceMaintenance = null

                this.setState({...this.state})
            })
        }
    }

    toggle() {
        this.setState({toggled: !this.state.toggled, menuOpen: false})
    }

    open() {
        this.setState({toggled: true, menuOpen: false})
    }

    close() {
        this.setState({toggled: false, menuOpen: false})
    }

    componentWillMount() {
        events.on('CLOSE_DEVICESTATUS', this.close);
        events.on('OPEN_DEVICESTATUS', this.open);
        //events.on(devices.types.DEVICE_SELECTED, this.open);
    }

    componentWillUnmount() {
        events.off('CLOSE_DEVICESTATUS', this.close)
        events.off('OPEN_DEVICESTATUS', this.open);
        //events.off(devices.types.DEVICE_SELECTED, this.open);
    }

    renderControl() {
        return (
            <div className="control" onClick={this.toggle}>
                <i className="mdi button"></i>
            </div>
        )
    }

    getAttributes() {
        const {device, position} = this.props;
        return (() => {
            const list = constants.position_attributes;
            const result = {};
            list.map(a => {
                result[a] = {
                    name: t('position' + a.replace(/^\w/g, function (s) {
                        return s.toUpperCase();
                    })),
                    key: a,
                    value: position.hasOwnProperty(a) ? position.getFormattedProperty(a) : undefined
                };
            })
            return result;
        })();
    }


    renderAttribute(attr) {
        return (
            <div className="device-attribute" key={attr.key}>
                <div className="name">{attr.name}</div>
                <div className="value">
                    {(() => {
                        switch (attr.key) {
                            case 'address':
                                if (!attr.value) {
                                    return <React.Fragment><span><Link href="#" component="button" color="primary"
                                                                       style={{verticalAlign: "top"}}
                                                                       onClick={this.getAddress}>{t('sharedShowAddress')}</Link></span>
                                        <Link color="#FF2525"
                                              href={`https://www.google.com/maps?q=${this.props.position.latitude},${this.props.position.longitude}&t=m&hl=${AppService.locale}`}
                                              target="_blank" className="ml-2"
                                        ><MapIcon className="mb-1"/>
                                        </Link>
                                    </React.Fragment>
                                } else {
                                    return <React.Fragment><span dangerouslySetInnerHTML={{__html: attr.value}}></span>
                                        <Link color="#FF2525"
                                              href={`https://www.google.com/maps?q=${this.props.position.latitude},${this.props.position.longitude}&t=m&hl=${AppService.locale}`}
                                              target="_blank" className="ml-2"
                                        ><MapIcon className="mb-1"/>
                                        </Link>
                                    </React.Fragment>
                                }
                            default:
                                return <React.Fragment><span
                                    dangerouslySetInnerHTML={{__html: attr.value}}></span></React.Fragment>
                        }
                    })()}

                </div>
            </div>
        );
    }

    renderDetails() {
        const {position} = this.props;
        if (position) {
            const attrs = this.getAttributes();
            const ret = [];
            if (attrs) {
                Object.values(attrs).map(a => {
                    ret.push(this.renderAttribute(a));
                })
            }
            const positionAttributes = position.attributes;
            if (positionAttributes instanceof Object) {
                Object.keys(positionAttributes).map(key => {
                    if (positionAttributes.hasOwnProperty(key) && !this.hideAttributes[key]) {
                        let value = position.hasAttribute(key) ? App.AttributeFormatter.getAttributeFormatter(key)(position.getAttribute(key)) : App.AttributeFormatter.defaultFormatter(positionAttributes[key]);
                        if (key === 'alarm' && value) {
                            value = t('alarm' + value.ucFirst());
                        } else if (key === 'event') {
                            //value = App.App.getEventString(value);
                        }
                        //console.log(key, value);
                        ret.push(this.renderAttribute({
                            key: key,
                            name: App.PositionAttributes.getAttributeName(key),
                            value: value
                        }))
                    }
                })
            }

            return ret;
        }
    }

    renderMaintenance() {
        const {device} = this.props;
        if (device) {
            const ret = [];

            //maintenance
            const maintenance = this.deviceMaintenance;
            ret.push(this.renderAttribute({
                key: "maintenanceSection",
                name: t('sharedMaintenance'),
                value: ""
            }))

            if (maintenance) {

                const maintenanceName = maintenance && maintenance.name ? maintenance.name : ""
                ret.push(this.renderAttribute({
                    key: "maintenanceName",
                    name: t('sharedName'),
                    value: maintenanceName
                }))

                const maintenanceCurrentDate = maintenance && maintenance.attributes['date'] ?
                    maintenance.attributes['date'] : null;
                ret.push(this.renderAttribute({
                    key: "maintenanceCurrentDate",
                    name: t('date'),
                    value: moment(maintenanceCurrentDate).format('DD/MM/YYYY')
                }))

                const repeatableSelected = maintenance && maintenance.attributes["repeatable"] ? maintenance.attributes["repeatable"] : false
                ret.push(this.renderAttribute({
                    key: "repeatableSelected",
                    name: t('repeatable'),
                    value: repeatableSelected
                }))

                if (repeatableSelected) {
                    const currentRepeatCount = maintenance && maintenance.attributes['repeatDayCount'] ? maintenance.attributes['repeatDayCount'] : 0;
                    const maintenanceRepeatType = maintenance && maintenance.attributes['repeatType'] ? maintenance.attributes['repeatType'] : 0;
                    ret.push(this.renderAttribute({
                        key: "currentRepeatCount",
                        name: t("Every"),
                        value: currentRepeatCount + " " + t(maintenanceRepeatType === 0 ? "sharedDays" : maintenanceRepeatType === 1 ? "months" : "years")
                    }))
                }
            }

            //tech inspection
            ret.push(this.renderAttribute({
                key: "techInspectionSection",
                name: t('technicalInspection'),
                value: ""
            }))
            if (device.attributes["techInspectionDate"]) {
                const techInspectionName = device.attributes["techInspectionName"] ?? ""
                ret.push(this.renderAttribute({
                    key: "techInspectionName",
                    name: t('sharedName'),
                    value: techInspectionName
                }))

                const techInspectionCurrentDate = device.attributes['techInspectionDate'] ?
                    new Date(device.attributes['techInspectionDate']) : null;
                ret.push(this.renderAttribute({
                    key: "techInspectionCurrentDate",
                    name: t('date'),
                    value: moment(techInspectionCurrentDate).format('DD/MM/YYYY')
                }))

                const techInspectionRepeatableSelected = device.attributes["techInspectionRepeatable"] ?? false
                ret.push(this.renderAttribute({
                    key: "techInspectionRepeatableSelected",
                    name: t('repeatable'),
                    value: techInspectionRepeatableSelected
                }))

                if (techInspectionRepeatableSelected) {
                    const techInspectionCurrentRepeatCount = device.attributes['techInspectionRepeatDayCount'] ?? 0
                    const techInspectionRepeatType = device.attributes['techInspectionRepeatType'] ?? 0;
                    ret.push(this.renderAttribute({
                        key: "techInspectionCurrentRepeatCount",
                        name: t("Every"),
                        value: techInspectionCurrentRepeatCount + " " + t(techInspectionRepeatType === 0 ? "sharedDays" : techInspectionRepeatType === 1 ? "months" : "years")
                    }))
                }
            }

            //insurance
            ret.push(this.renderAttribute({
                key: "insuranceSection",
                name: t('insurance'),
                value: ""
            }))

            if (device.attributes["insuranceDate"]) {
                const insuranceName = device.attributes["insuranceName"] ?? ""
                ret.push(this.renderAttribute({
                    key: "insuranceName",
                    name: t('sharedName'),
                    value: insuranceName
                }))

                const insuranceCurrentDate = device.attributes['insuranceDate'] ?
                    new Date(device.attributes['insuranceDate']) : null;
                ret.push(this.renderAttribute({
                    key: "insuranceCurrentDate",
                    name: t('date'),
                    value: moment(insuranceCurrentDate).format('DD/MM/YYYY')
                }))

                const insuranceRepeatableSelected = device.attributes["insuranceRepeatable"] ?? false
                ret.push(this.renderAttribute({
                    key: "insuranceRepeatableSelected",
                    name: t('repeatable'),
                    value: insuranceRepeatableSelected
                }))
                if(insuranceRepeatableSelected) {
                    const insuranceCurrentRepeatCount = device.attributes['insuranceRepeatDayCount'] ?? 0
                    const insuranceRepeatType = device.attributes['insuranceRepeatType'] ?? 0
                    ret.push(this.renderAttribute({
                        key: "insuranceCurrentRepeatCount",
                        name: t("Every"),
                        value: insuranceCurrentRepeatCount + " " + t(insuranceRepeatType === 0 ? "sharedDays" : insuranceRepeatType === 1 ? "months" : "years")
                    }))
                }
            }

            return ret;
        }
    }

    renderStreetView() {
        const {position} = this.props;
        if (position && constants.enableStreetView) {
            const urlSRC = `https://google.com/maps/embed/v1/streetview?key=AIzaSyANWGQ-hTUpBzW6NCVKdPub2OmjxvqAUwg&location=${position.latitude},${position.longitude}&heading=${position.course}&pitch=10&fov=70`;
            return (<div className="googleStreetView flex-grow-0">
                <iframe id="embedStreet"
                        name="embedStreet"
                        scrolling="no"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        src={`${urlSRC}`}>< /iframe>
            </div>)
        }
        return null;
    }

    // getDoorMap(doorAttr) {
    //     let door = {}
    //     if (doorAttr === "16128") {
    //         door = t("allDoorsOpen")
    //     } else {
    //         door['256'] = "FL"
    //         door['512'] = "FR"
    //         door['768'] = "FL, FR"
    //         door['1024'] = 'RL'
    //         door['1280'] = 'FL, RL'
    //         door['1536'] = 'FR, RL'
    //         door['1792'] = 'FL, FR, RL'
    //
    //         const fl = {value: 256, text: "FL"}
    //         const fr = {value: 512, text: "FR"}
    //         const rl = {value: 1024, text: "RL"}
    //         const rr = {value: 2048, text: "RR"}
    //         const h = {value: 4096, text: "H"}
    //         const t = {value: 8192, text: "T"}
    //         const list = [fl, fr, rl, rr, h, t]
    //
    //         function add(item, item2) {
    //             if (item.value !== item2.value) {
    //                 const key = (item.value + item2.value).toString()
    //                 if (door[key] === undefined) {
    //                     door[key] = item.text + ", " + item2.text
    //                 }
    //             }
    //         }
    //
    //         for (let i = 0; i < list.length; i++) {
    //             const item = list[i]
    //             for (let j = 0; j < list.length; j++) {
    //                 const item2 = list[j]
    //                 add(item,item2)
    //             }
    //         }
    //     }
    //     return door
    // }

    renderSensors() {
        // const doorMap = this.getDoorMap()
        const {device, position} = this.props;
        const io84 = position.getAttribute("io84")
        let door = ''
        if (position.getAttribute('door') !== undefined) {
            const doorAttr = position.getAttribute('door').toString()

            if (doorAttr === '0') {
                door = t('closed')
            } else if (doorAttr === "16128") {
                door = t("allDoorsOpen")
            } else {
                door = t('open')
                // if (doorAttr === '256') {
                //     door += "FL"
                // }
                // if (doorAttr === '512') {
                //     door += ",FR"
                // }
                // if (doorAttr === '1024') {
                //     door += ",RL"
                // }
                // if (doorAttr === '2048') {
                //     door += ",RR"
                // }
                // if (doorAttr === '4096') {
                //     door += ",H"
                // }
                // if (doorAttr === '8192') {
                //     door += ",T"
                // }
                //
                // if (door.startsWith(","))
                //     door = door.slice(1)
                //
                // if (door !== '')
                //     door = door + " " + t("open")
            }
        }
        return (
            <React.Fragment>
                <div className="row" style={{width: '99%'}}>
                    <div className="col-12">
                        <div className="device-sensor">
                            <span className={`name`}>{t('positionAddress')}</span>
                            <span className="value">
                                {(() => {
                                    if (!position.address) {
                                        return <React.Fragment>
                                            <Link href="#" component="button" color="primary" className="m-0 p-0"
                                                  style={{verticalAlign: "top"}}
                                                  onClick={this.getAddress}>{t('sharedShowAddress')}</Link>
                                            <Link color="#FF2525"
                                                  href={`https://www.google.com/maps?q=${position.latitude},${position.longitude}&t=m&hl=${AppService.locale}`}
                                                  target="_blank" className="ml-2"
                                            ><MapIcon className="mb-1"/>
                                            </Link>
                                        </React.Fragment>
                                    } else {
                                        return <React.Fragment>
                                            <span className="m-0 p-0"
                                                  dangerouslySetInnerHTML={{__html: position.address}}></span>
                                            <Link color="#FF2525"
                                                  href={`https://www.google.com/maps?q=${position.latitude},${position.longitude}&t=m&hl=${AppService.locale}`}
                                                  target="_blank" className="ml-2"
                                            ><MapIcon className="mb-1"/>
                                            </Link>
                                        </React.Fragment>
                                    }
                                })()}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row" style={{width: '99%'}}>
                    <div className="col-12 col-sm-4">
                        <div className="device-sensor">
                            <span className="name">
                                <span className={`sensor device-status online-status ${device.status}`}><i
                                    className="mdi mdi-satellite-variant"></i></span>
                                {t('deviceStatus')}
                            </span>
                            <span className="value">{t('deviceStatus' + device.status.ucFirst())}</span>
                        </div>
                        <div className="device-sensor">
                            <span className="name">
                                <span
                                    className={'sensor device-status motion-status ' + (position && position.hasAttribute('motion') ? (position.getAttribute('motion') ? 'moving' : 'stopped') : 'unknown')}
                                    title={position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}><i
                                    className="mdi mdi-run-fast"></i></span>
                                {t('positionMotion')}
                            </span>
                            <span
                                className="value"> {position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}</span>
                        </div>
                        <div className="device-sensor">
                            <span className="name">
                                <span
                                    className={'sensor device-status speed-status ' + (position && position.speed > 0 ? 'moving' : 'stopped')}
                                    title={position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}><i
                                    className="mdi mdi-speedometer"></i></span>
                                {t('positionSpeed')}
                            </span>
                            <span className="value">
                                {position.getFormattedProperty('speed')}
                            </span>
                        </div>
                        {position && position.hasAttribute('odometer') ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span className={'sensor device-status odometer-status '}
                                          title={position && position.getAttribute('motion') ? t('eventDeviceMoving') : t('eventDeviceStopped')}><i
                                        className="mdi mdi-counter"></i></span>
                                    {t('positionOdometer')}
                                </span>
                                <span className="value">
                                    {App.AttributeFormatter.getFormatter('distance')(position.getAttribute('odometer'))}
                                </span>
                            </div> : null}

                    </div>
                    <div className="col-12 col-sm-4">
                        {position && position.hasAttribute('result') && false ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span className={'sensor device-status text-dark'}
                                          title={position && position.getAttribute('result') ? position.getAttribute('result') : ''}><i
                                        className="mdi mdi-counter"></i></span>
                                    {t('eventCommandResult')}
                                </span>
                                <span className="value">
                                    {position.getAttribute('result')}
                                </span>
                            </div> : null}

                        {position && position.hasAttribute('hours') ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span className={'sensor device-status text-dark '}><i
                                        className="mdi mdi-clock"></i></span>
                                    {t('positionHours')}
                                </span>
                                <span className="value">
                                    {position.getFormattedAttribute('hours')}
                                </span>
                            </div> : null}

                        {

                            position && position.hasAttribute('ignition') ?
                                <
                                    React.Fragment>
                                    <
                                        div className="device-sensor">
                                     <
                                         span className="name">
                                     <
                                         span className={
                                         'sensor device-status ignition-status attribute ' + (position && position.hasAttribute('ignition') ? (position.getAttribute('ignition') ? 'on' : 'off') : 'unknown')
                                     }
                                              title={
                                                  position && position.hasAttribute('ignition') ? (position.getAttribute('ignition') ? 'on' : 'off') : 'unknown'
                                              }> < i className="mdi mdi-engine-outline"> < /i></span> {
                                         t('positionIgnition')
                                     } <
                                     /span> <
                                        span className="value"> {
                                        App.AttributeFormatter.getFormatter('boolean')(position.getAttribute('ignition')).toString() === 'true' ? t("on") : t("off")
                                    } <
                                     /span><
                                     /div>
                                <
                                     /React.Fragment> : null
                        }

                        {


                            // position && position.hasAttribute('hours') ?
                            //      <
                            //    div className = "device-sensor" >
                            //       <
                            //      span className = "name" >
                            //      <
                            //      span className = {
                            //          'sensor device-status text-danger '
                            //     } > < i className = "mdi mdi-thermometer" > < /i></span > {
                            //         t('Body temp')
                            //      } <
                            //      /span> <
                            //   span className = "value" > {
                            //         position.getFormattedAttribute('temp1')
                            //       } <
                            //      /span> < /
                            //   div >: null
                            //    }

                            //     {

                            position && position.hasAttribute('power') ?
                                <div className="device-sensor">
                                <span className="name">
                                    <span
                                        className={'sensor device-status power-status ' +
                                        (position.hasAttribute('power') ? (() => {
                                                if (position.getAttribute('power') > 12.4) {
                                                    return 'text-success';
                                                } else if (position.getAttribute('power') > 11.8) {
                                                    return 'text-warning';
                                                } else {
                                                    return 'text-danger'
                                                }
                                            })()
                                            : '')}><i className={'mdi ' + (() => {
                                        if (position.getAttribute('power') > 12.4) {
                                            return 'mdi-battery';
                                        } else if (position.getAttribute('power') > 11.8) {
                                            return 'mdi-battery-50';
                                        } else {
                                            return 'mdi-battery-10'
                                        }
                                    })()}></i></span>
                                    {t('Battery')}
                                </span>
                                    <span className="value"
                                          dangerouslySetInnerHTML={{__html: position.getFormattedAttribute('power')}}>
                                </span>
                                </div> : null}


                        {
                            position && position.hasAttribute('io839') ?
                                <div className="device-sensor">
                                <span className="name">
                                    <span
                                        className={'sensor device-status power-status ' +
                                        (position.hasAttribute('io839') ? (() => {
                                                const io839 = position.getAttribute('io839') / 1000
                                                if (io839 > 12.4) {
                                                    return 'text-success';
                                                } else if (io839 > 11.8) {
                                                    return 'text-warning';
                                                } else {
                                                    return 'text-danger'
                                                }
                                            })()
                                            : '')}><i className={'mdi ' + (() => {
                                        const io839 = position.getAttribute('io839') / 1000
                                        if (io839 > 12.4) {
                                            return 'mdi-battery';
                                        } else if (io839 > 11.8) {
                                            return 'mdi-battery-50';
                                        } else {
                                            return 'mdi-battery-10'
                                        }
                                    })()}></i></span>
                                    {t('Battery')}
                                </span>
                                    <span className="value"
                                          dangerouslySetInnerHTML={{__html: (position.getAttribute('io839') / 1000) + " V"}}>
                                </span>
                                </div> : null}


                        {


                            position && position.hasAttribute('hours') ?
                                <
                                    div className="device-sensor">
                                    <
                                        span className="name">
                                    <
                                        span className={
                                        'sensor device-status text-success '
                                    }> < i className="mdi mdi-account-star"> < /i></span> {
                                        t('Driver')
                                    } <
                                    /span> <
                                    span className="value"> {
                                    position.getFormattedAttribute('driverUniqueId')
                                }
                                    </span>
                                </div> : null}
                        {
                            position && position.hasAttribute('io84') ?
                                <React.Fragment>

                                    <div className="device-sensor">

                                     <span className="name">

                                     <span className={'sensor device-status power-status ' +
                                     (position.hasAttribute('io84') ? (() => {

                                             if (io84 === null || io84 === undefined) {
                                                 return 'text-info';
                                             }

                                             if (io84 <= 50 && io84 > 15) {
                                                 return 'text-warning'
                                             } else if (io84 <= 15) {
                                                 return 'text-danger'
                                             } else {
                                                 return 'text-success'
                                             }
                                         })()
                                         : '')}>
                                         <i className="mdi mdi-gas-station"> < /i></span> {

                                         t('Fuel')

                                     } </span>
                                        <span
                                            className="value"> {App.AttributeFormatter.volumeFormatter(io84 / 10)} </span>
                                    </div>
                                </React.Fragment>
                                : null
                        }
                    </div>
                    <div className="col-12 col-sm-4">


                        {position && position.hasAttribute('rssi') ?
                            <
                                div className="device-sensor">
                                    <
                                        span className="name">
                                    <
                                        span className={
                                        'sensor device-status text-success '
                                    }> < i className="mdi mdi-signal"> < /i></span> {
                                        t('Signal')
                                    } <
                                    /span> <
                                span className="value"> {
                                position.getFormattedAttribute('rssi')
                            } <
                                    /span>< /
                                div> : null
                        }

                        {position && position.hasAttribute('motion') ?
                            <
                                div className="device-sensor">
                                    <
                                        span className="name">
                                    <
                                        span className={
                                        'sensor device-status text-dark '
                                    }> < i className="mdi mdi-update"> < /i></span> {
                                        t('deviceLastUpdate')
                                    } <
                                    /span> <
                                span className="value"> {
                                device.getFormattedProperty('lastUpdate')
                            }
                                    </span>
                            </div> : null}


                        {position && position.hasAttribute('hours') ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span className={'sensor device-status text-dark '}><i
                                        className="mdi mdi-satellite-uplink"></i></span>
                                    {t('Satellites')}
                                </span>
                                <span className="value">
                                    {position.getFormattedAttribute('sat')}
                                </span>
                            </div> : null}


                        {position && position.hasAttribute('out1') ?
                            <React.Fragment>

                                <div className="device-sensor">

                                     <span className="name">

                                     <span className={'sensor device-status power-status ' +
                                     (position.hasAttribute('out1') ? (() => {
                                             if (position.getAttribute('out1')) {
                                                 return 'text-success';
                                             } else {
                                                 return 'text-danger'
                                             }
                                         })()
                                         : '')}>
                                         <i className="mdi mdi-shield-key"> < /i></span> {

                                         t('lock')

                                     } </span>

                                    <span className="value"> {
                                        App.AttributeFormatter.getFormatter('boolean')(position.getAttribute('out1')).toString() === 'true' ?
                                            t('locked') : t('unlocked')
                                    } </span><

                                    /div>
                            </React.Fragment> : null}


                        {
                            position && position.hasAttribute('di2') ?
                                <React.Fragment>

                                    <div className="device-sensor">

                                     <span className="name">

                                     <span className={'sensor device-status power-status ' +
                                     (position.hasAttribute('di2') ? (() => {
                                             if (position.getAttribute('di2') !== undefined && position.getAttribute('di2').toString() === '0') {
                                                 return 'text-success';
                                             } else {
                                                 return 'text-danger'
                                             }
                                         })()
                                         : '')}>
                                         <i className="mdi mdi-car-door"> </i></span> {

                                         t('doors')

                                     } </span>

                                        <span className="value"> {
                                            App.AttributeFormatter.getFormatter('boolean')(position.getAttribute('di2') !== undefined && position.getAttribute('di2')).toString() === '0' ?
                                                t('closed') : t('open')
                                        } </span><

                                    /div>
                                </React.Fragment>
                                : null
                        }

                        {
                            position && position.hasAttribute('door') ?
                                <React.Fragment>

                                    <div className="device-sensor">

                                     <span className="name">

                                     <span className={'sensor device-status power-status ' +
                                     (position.hasAttribute('door') ? (() => {
                                             if (position.getAttribute('door') !== undefined && position.getAttribute('door').toString() === '0') {
                                                 return 'text-success';
                                             } else {
                                                 return 'text-danger'
                                             }
                                         })()
                                         : '')}>
                                         <i className="mdi mdi-car-door"> < /i></span> {

                                         t('doors')

                                     } </span>

                                        <span className="value"> {door} </span><

                                    /div>
                                </React.Fragment>
                                : null
                        }


                        {position && position.hasAttribute('batteryLevel') ?
                            <div className="device-sensor">
                                <span className="name">
                                    <span
                                        className={'sensor device-status battery-status ' + (position.hasAttribute('batteryLevel') ? (() => {
                                            if (position.getAttribute('batteryLevel') > 50) {
                                                return 'text-success';
                                            } else if (position.getAttribute('batteryLevel') > 30) {
                                                return 'text-warning';
                                            } else {
                                                return 'text-danger'
                                            }
                                        })() : '')}><i className={'mdi ' + (() => {
                                        if (position.getAttribute('batteryLevel') > 50) {
                                            return 'mdi-battery';
                                        } else if (position.getAttribute('batteryLevel') > 30) {
                                            return 'mdi-battery-30';
                                        } else {
                                            return 'mdi-battery-10'
                                        }
                                    })()}></i></span>
                                    {t('positionBattery')}
                                </span>
                                <span className="value"
                                      dangerouslySetInnerHTML={{__html: position.getFormattedAttribute('batteryLevel')}}>
                                </span>
                            </div> : null}
                        {


                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

    selectTab(name) {
        this.setState({currentTab: name})
    }

    toggleMenu() {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    showEdit(e) {
        e.stopPropagation();
        store.dispatch(app.actions.showModal({
            name: 'device-form',
            icon: 'mdi mdi mdi-car',
            title: t('sharedEdit') + ' ' + t('deviceTitle')
        }, {item: this.props.device, onSave: this.save}))
    }

    async save(item) {
        try {
            await DevicesService.update(item);
            store.dispatch(app.actions.hideModal());
            store.dispatch(devices.actions.updated(item));
            Events.emit(devices.types.UPDATED, item)
            store.dispatch(app.actions.hideModal());
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    showCommand(e) {
        e.stopPropagation();
        this.setState({showCommand: true})
    }

    hideCommand() {
        this.setState({showCommand: false});
    }

    renderCommandForm() {
        const {device, position} = this.props;
        return <CommandModal onHide={this.hideCommand} device={device} position={position}/>
    }

    renderContent() {
        const {device, position} = this.props;
        return (
            <React.Fragment>
                <ThemeProvider theme={theme}>
                    {this.state.showCommand ? this.renderCommandForm() : null}
                    <div className="header flex-grow-0 d-flex flex-row" style={{height: "2.7rem"}}>
                        <h1 className="title flex-grow-1" style={{"paddingTop": "0.35rem"}}>
                            {(process.env.NODE_ENV !== 'production') ? device.id + ' - ' : null}{device.name}
                        </h1>
                        <div className="controls flex-grow-0 p-0">
                            <Button color={(this.state.currentTab === 'sensors' ? 'primary' : 'secondary')}
                                    onClick={() => this.selectTab('sensors')}
                                    className={"icon-btn-blue ml-2"}>
                                <RouterIcon color="warn" fontSize="small"></RouterIcon>
                            </Button>
                            <Button color={(this.state.currentTab === 'position' ? 'primary' : 'secondary')}
                                    onClick={() => this.selectTab('position')}
                                    className={"icon-btn-blue "}>
                                <AlignIcon color="warn" fontSize="small"></AlignIcon>
                            </Button>
                            {/*<Button color={(this.state.currentTab === 'maintenance' ? 'primary' : 'secondary')}*/}
                            {/*        onClick={() => this.selectTab('maintenance')}*/}
                            {/*        className={"icon-btn-blue "}>*/}
                            {/*    <MaintenanceIcon color="warn" fontSize="small"></MaintenanceIcon>*/}
                            {/*</Button>*/}
                            <Button color={(this.state.currentTab === 'streetView' ? 'primary' : 'secondary')}
                                    onClick={() => this.selectTab('streetView')}
                                    className={"icon-btn-blue d-md-none"}>
                                <CameraIcon color="warn" fontSize="small"></CameraIcon>
                            </Button>
                        </div>
                        { App.App.user.id === customReadOnlyUser ? null :
                        <div className="controls flex-grow-0">
                            <DropdownButton
                                drop='up'
                                className="dropdown-blue"
                                title={
                                    <SettingsIcon color="warn" fontSize="small"></SettingsIcon>
                                }
                            >
                                {App.App.userHasPermission('devices') ? <Dropdown.Item onClick={(e) => {
                                    this.showEdit(e)
                                }}>
                                    <i className="mdi mdi-pencil"></i> {t('sharedEdit')}
                                </Dropdown.Item> : null}
                                <Dropdown.Item onClick={(e) => {
                                    this.showCommand(e)
                                }}>
                                    <i className="mdi mdi-apple-keyboard-command"></i> {t('commandTitle')}
                                </Dropdown.Item>

                                {position && position.hasAttribute('out1') ?
                                    <Dropdown.Item onClick={async (e) => {
                                        const command = new Command()
                                        command.description = "New"
                                        command.type = "custom"
                                        command.attributes = {
                                            data: "setdigout " + (position.attributes['out1'] ? "0" : "1")
                                        }
                                        command.deviceId = position.deviceId
                                        command.id = 0
                                        command.textChannel = null

                                        const response = await CommandsService.send(command)

                                        if (response.status === 202) {
                                            App.App.showToast(t('commandQueued'), '', {
                                                type: 'success',
                                            })
                                        } else {
                                            App.App.showToast(t('commandSent'), '', {
                                                type: 'success',
                                            })
                                        }
                                    }}>
                                        <i className={"mdi mdi-shield-key " +
                                        (position.hasAttribute('out1') ? (() => {
                                                if (position.getAttribute('out1')) {
                                                    return 'text-success';
                                                } else {
                                                    return 'text-danger'
                                                }
                                            })()
                                            : '')}/> {
                                        t(position.attributes['out1'] ? 'unlock' : 'lock')
                                    }
                                    </Dropdown.Item> : null
                                }
                            </DropdownButton>
                        </div>
                        }
                    </div>
                    <div className="content flex-grow-1 row">
                        {device && this.state.currentTab === 'maintenance' ?
                            <div
                                className={"details d-tab col-12 col-md-8 p-1 " + (this.state.currentTab === 'maintenance' ? 'active' : '')}>
                                <Scrollbars>
                                    {this.renderMaintenance()}
                                </Scrollbars>
                            </div> : device && position ?
                                <React.Fragment>
                                    <div
                                        className={"details d-tab col-12 col-md-8 p-1 " + (this.state.currentTab === 'sensors' ? 'active' : '')}>
                                        <Scrollbars>
                                            {this.renderSensors()}
                                        </Scrollbars>
                                    </div>
                                    <div
                                        className={"details d-tab col-12 col-md-8 p-1 " + (this.state.currentTab === 'position' ? 'active' : '')}>
                                        <Scrollbars>
                                            {this.renderDetails()}
                                        </Scrollbars>
                                    </div>
                                    <div
                                        className={"details d-street col-12 col-md-4 p-0 " + (this.state.currentTab === 'streetView' ? 'active' : '')}>
                                        {this.renderStreetView()}
                                    </div>
                                </React.Fragment> :
                                (
                                    <div className="alert alert-warning col-12 flex-grow-0"
                                         style={{height: '3em'}}>Position
                                        not
                                        loaded
                                    </div>
                                )}
                    </div>
                </ThemeProvider>
            </React.Fragment>

        )
    }

    async getAddress() {
        try {
            const address = await PositionsService.findAddressFromLatLng(this.props.position.latitude, this.props.position.longitude);
            const newPosition = new Position().deserialize(this.props.position);
            newPosition.address = address;
            store.dispatch(positions.actions.updated(newPosition));
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    render() {
        const {device} = this.props;
        return (
            <div
                className={'device-attributes d-flex flex-column app-bottombar ' + (this.state.toggled || !device ? 'toggled' : '') + ' ' + (this.props.className || '')}>
                {device ? this.renderContent() : null}
                {(this.props.controlled !== undefined || !this.props.controlled ? this.renderControl() : null)}
            </div>
        )
    }
}
