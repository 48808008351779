import { ReportPosition } from "../models";
import { store } from "../store";
import positions from "../store/positions";
import events from "../utils/Events";
import { convertToQs } from '../utils/functions';
import ApiService from "./ApiService";

export class PositionsService extends ApiService {
    constructor() {
        super();
        this.baseUrl = 'positions';
    }

    async load(params = {}) {
        const items = await super.list(params);
        //update the store
        store.dispatch({ type: positions.types.LOADED, payload: { items: items } });
        events.emit(positions.types.LOADED, { positions: items });

        /*items.map(p => {
            const device = store.getState().devices.devices[p.deviceId];
            if (device && ((!device.position) || device.position.id != p.id)) {
                device.history = [];
                device.position = p;
                store.dispatch(devices.actions.updated(device));
                events.emit(devices.types.UPDATED, device);
            }
        });*/

        return items;
    }

    async findAddressFromPosition(position) {
        const address =  await this.findAddressFromLatLng(position.latitude, position.longitude);
        position.address = address;
        store.dispatch(positions.actions.updated(position));
    }

    async findAddressFromLatLng(latitude, longitude) {
        const { data } = await this.api.get('/server/geocode/?' + convertToQs({ latitude, longitude }));
        return data;
    }

    async listReportPositions(params = {}) {
        let init = true
        let hasMore = false
        let startingId = -1

        let finalData = []

        while (init === true || hasMore) {
            if (startingId > -1)
                params.startingId = startingId

            const { data } = await this.api.get(this.baseUrl + '/v2/?' + convertToQs(params));
            console.log("Fetched page ", data.length)
            hasMore = data && data.length && data.length === 20000
            startingId = hasMore ? data[data.length - 1].id : -1

            finalData = finalData.concat(data)

            console.log('hasMore', hasMore, 'startingId', startingId)
            if (init) init = false;
        }

        console.log("Total report positions", finalData.length)
        return this.transformListResponse(finalData);
    }

    transformSingleResponse(data) {
        const d = new ReportPosition().deserialize(data);
        return d;
    }


}

export default new PositionsService();