import React from 'react';
import {t} from '../../../utils/Translator';
import TextFilter from '../../Controls/TextFilter';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import {App} from "../../../App";
import DevicesService from "../../../services/DevicesService";
import moment from "moment";
import constants from "../../../config/constants";
import events from "../../../utils/Events";
import ReactExport from "react-export-excel";
import {MDBDataTable} from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import Pencil from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class List extends React.Component {

    static deleteEvent = false

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            selectedItems: props.selectedItems || [],
            allSelected: false,
        }

        this.deleteItem = this.deleteItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.unselectAll = this.unselectAll.bind(this);

        this.columns = [
            {
                label: t("sharedDevice"),
                field: "name",
                sort: 'asc',
                height: 80
            },
            {
                label: t("deviceIdentifier"),
                field: "uniqueId",
                sort: 'asc',
            },
            {
                label: t("serverRegistration"),
                field: "attributes.registrationDate",
                sort: 'asc',
            },
            {
                label: t("userExpirationTime"),
                field: "attributes.expirationDate",
                sort: 'asc',
            },
            {
                label: t("customer"),
                field: "attributes.createdBy",
                sort: 'asc',
            },
            {
                label: t("deviceLastUpdate"),
                field: "lastUpdate",
                sort: 'asc',
            }
        ]

        const enableDelete = this.props.enableDelete !== undefined ? this.props.enableDelete : false;

        if (enableDelete)
            this.columns.push({
                label: t("actions"),
                field: "sharedRemove",
                sort: "disabled",
            })
    }

    componentDidMount() {
        if (App.App.user.administrator) {
            DevicesService.listAll({all: true}, false).then(devs => {
                this.setState({props: devs})
            })
        }
    }

    deleteItem(id) {
        if (this.props.onDelete) {
            this.props.onDelete(id);
        }
    }

    selectItem(id) {
        this.toggleItem(id, () => {
            if (this.props.onSelect) {
                this.props.onSelect(id, this.isItemSelected(id));
            }
        });

    }

    setFilter(value) {
        if (value != this.state.filter) {
            this.setState({filter: value})
        }
    }

    filterValues(values) {
        if (this.state.filter && this.state.filter !== '') {
            return Object.values(values).filter(o => {
                let owner = o.attributes['owner']
                if (owner === undefined || owner === null) owner = ""
                let customer = o.attributes['createdBy']
                if (customer === undefined || customer === null) customer = ""
                let contact = o.contact
                if (contact === undefined || contact === null) contact = ""
                return o.uniqueId.toLowerCase().includes(this.state.filter.toLowerCase())
                    || o.name.toLowerCase().includes(this.state.filter.toLowerCase())
                    || contact.toLowerCase().includes(this.state.filter.toLowerCase())
                    || owner.toLowerCase().includes(this.state.filter.toLowerCase())
                    || customer.toLowerCase().includes(this.state.filter.toLowerCase())
            })
        }
        return Object.values(values)
    }

    toggleItem(id, callback) {
        const selectionMode = this.props.selectionMode != undefined ? this.props.selectionMode : 'multiple';
        if (selectionMode !== 'multiple') {
            this.setState({selectedItems: []});
        }
        let selectedItems = this.state.selectedItems;
        if (this.isItemSelected(id)) {
            selectedItems = selectedItems.filter(o => o != id);
        } else {
            selectedItems.push(id);
        }
        this.setState({selectedItems: selectedItems, allSelected: false}, callback);
    }


    selectAll() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({selectedItems: selectedItems, allSelected: true}, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    unselectAll() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({selectedItems: [], allSelected: false}, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    toggleAll() {
        if (this.state.allSelected) {
            this.unselectAll();
        } else {
            this.selectAll();
        }
    }

    isItemSelected(id) {
        return (this.state.selectedItems ? this.state.selectedItems.includes(id) : false);
    }

    render() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const enableFilter = this.props.enableFilter != undefined ? this.props.enableFilter : true;
        const enableDelete = this.props.enableDelete != undefined ? this.props.enableDelete : false;
        const enableSelection = this.props.enableSelection != undefined ? this.props.enableSelection : false;

        events.emit("filteredDevices", values)

        const rows = []

        values.forEach(row => {
            rows.push(
                {
                    name: <div searchvalue={row.name.ucFirst()}>{
                        !row.name ? "" : t(String(row.name)).ellipsisEnd()
                    }</div>,
                    uniqueId: t(row.getFormattedProperty('uniqueId', false)).ellipsisEnd(),
                    "attributes.createdBy": <div
                        searchvalue={row.attributes['createdBy'] ? String(row.attributes['createdBy']).ucFirst() : ""}>{
                        t(row.getAttribute('createdBy', false)).ellipsisEnd()
                    }</div>,
                    "attributes.registrationDate":
                        <div searchvalue={row.attributes["registrationDate"] ?? 0}>{
                            row.attributes["registrationDate"] ? moment(new Date(row.attributes["registrationDate"])).format(constants.dateFormat) : ""
                        }</div>,
                    "attributes.expirationDate":
                        <div searchvalue={row.attributes["expirationDate"] ?? 0}>{
                            row.attributes["expirationDate"] ? moment(new Date(row.attributes["expirationDate"])).format(constants.reportDateFormat) : ""
                        }</div>,
                    "lastUpdate":
                        <div searchvalue={!isNaN(row.lastUpdate) ? (new Date(row.lastUpdate).getTime()) : 0}>{
                            t(row.getFormattedProperty('lastUpdate'))
                        }</div>,
                    "sharedRemove":
                        <div className="flexbox-container"
                             style={{
                                 display: "flex",
                                 flexDirection: "row"
                             }}>
                            <IconButton style={{
                                border: "none",
                                outline: "none"
                            }} color="primary" className="p-0 ml-3 mr-4 mt-0 mb-0" onClick={() => {
                                if (List.deleteEvent) {
                                    List.deleteEvent = false;
                                    return
                                }
                                this.selectItem(row.pk)
                            }}><Pencil color="primary" fontSize="small"></Pencil></IconButton>
                            <IconButton style={{
                                border: "none",
                                outline: "none"
                            }} color="error" className="p-0 mr-2" onClick={() => {
                                if (!row.disabled || App.App.user.administrator) {
                                    this.deleteItem(row.pk)
                                    List.deleteEvent = true;
                                }
                            }}><DeleteIcon color="warn" fontSize="small"></DeleteIcon></IconButton>
                        </div>,
                }
            )
        })

        const data = {
            columns: this.columns,
            rows: rows
        }


        return <React.Fragment>
            <header className="header-small w-100">
                <div className="container d-flex pb-0">
                    <h3 className="text-white"><i className="mdi mdi-car"></i> {
                        t('deviceTitle') + " - " + (values.length ? values.length : 0)}</h3>
                </div>
            </header>

            <div className="p-2">
                <div className="list-tools">
                    {enableSelection ?
                        <div className="list-controls">
                            <CustomCheckbox checked={this.state.allSelected} onChange={() => this.toggleAll()}/>
                        </div>
                        : null}
                    {enableFilter ?
                        <React.Fragment><TextFilter onFilter={(value) => this.setFilter(value)}/></React.Fragment>
                        : null}
                    <hr/>
                </div>

                <section className="ml-2 mr-2 statisc-table">
                    <div className="flex-grow-1 overflow">
                        <MDBDataTable
                            infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                            noRecordsFoundLabel={t("sharedEmtpyList")}
                            paginationLabel={[t("previous"), t("next")]}
                            entriesLabel={t("showEntries")}
                            striped
                            hover
                            searching={false}
                            bordered
                            small
                            noBottomColumns={true}
                            data={data}
                            sortRows={['name', 'attributes.registrationDate', 'attributes.expirationDate', 'lastUpdate']}
                        />
                    </div>
                </section>
            </div>

            {/*{values.length > 0 ? <ListGroup>*/}
            {/*    {values.map(i => {*/}
            {/*        return <ListGroup.Item className="list-group-item list-group-item-action border-1" key={i.pk}>*/}
            {/*            {enableSelection ?*/}
            {/*                <CustomCheckbox checked={this.isItemSelected(i.pk)} onChange={() => {*/}
            {/*                    this.selectItem(i.pk)*/}
            {/*                }}/>*/}
            {/*                : null}*/}
            {/*            <div className="content" onClick={() => {*/}
            {/*                if (!i.disabled || App.App.user.administrator)*/}
            {/*                    this.selectItem(i.pk)*/}
            {/*            }}>*/}
            {/*                <strong className="name">{i.name}</strong>*/}
            {/*                <span className="value">*/}
            {/*                    <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('deviceIdentifier')}:</span>*/}
            {/*                        <span className="attr-value">{t(i.getFormattedProperty('uniqueId'))}</span>*/}
            {/*                    </span>*/}
            {/*                    <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('serverRegistration')}:</span>*/}
            {/*                        <span*/}
            {/*                            className="attr-value">{i.attributes["registrationDate"] ? moment(new Date(i.attributes["registrationDate"])).format(constants.dateFormat) : ""}</span>*/}
            {/*                    </span>*/}
            {/*                    <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('userExpirationTime')}:</span>*/}
            {/*                        <span*/}
            {/*                            className="attr-value">{i.attributes["expirationDate"] ? moment(new Date(i.attributes["expirationDate"])).format(constants.dateFormat) : ""}</span>*/}
            {/*                    </span>*/}
            {/*                    <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('customer')}:</span>*/}
            {/*                        <span className="attr-value">{i.attributes['createdBy'] ? i.attributes['createdBy'] : ""}</span>*/}
            {/*                    </span>*/}
            {/*                    <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('Last Update')}:</span>*/}
            {/*                        <span className="attr-value">{t(i.getFormattedProperty('lastUpdate'))} </span>*/}
            {/*                    </span>*/}
            {/*                </span>*/}
            {/*            </div>*/}
            {/*            {enableDelete ? <div className="actions">*/}
            {/*                <Button variant="default" className="danger" onClick={() => {*/}
            {/*                    if (!i.disabled || App.App.user.administrator)*/}
            {/*                        this.deleteItem(i.pk)*/}
            {/*                }}><i className="mdi mdi-delete"></i></Button>*/}
            {/*            </div> : null}*/}
            {/*        </ListGroup.Item>*/}
            {/*    })}*/}
            {/*</ListGroup> : <EmptyList/>}*/}
        </React.Fragment>
    }

}